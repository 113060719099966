<template>
  <template v-if="loading">
    <ProgressSpinner />
  </template>
  <template v-else>
    <div class="content-spec-list">
      <div class="content">
        <img :src="'../../images/jmf_logo.jpg'" style="height: 100px" />
        <span class="spectacle-list-title roboto-slab"
          >Liste des spectacles</span
        >

        <div class="ml-5 mr-5"></div>
      </div>

      <footer class="bg-dark text-center text-white pied-de-page">
        <div class="display: flex; flex-direction: column;">
          <p style="margin-top: 7px">
            Retrouvez nous sur nos réseaux sociaux :
          </p>

          <section class="mb-2">
            <a
              class="btn btn-outline-light btn-floating m-1 facebook-button"
              href="https://www.facebook.com/jmfrancelemanssarthe/"
              role="button"
            >
              <i class="fab fa-facebook-f"></i>
            </a>

            <a
              class="btn btn-outline-light btn-floating m-1 instagram-button"
              href="https://www.instagram.com/jmfrancesarthe/"
              role="button"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </section>

          <a href="mailto:jmfrancereseaux@gmail.com" style="color: #ea575c"
            >Contactez-nous en cas de problème à l'adresse :
            jmfrancereseaux@gmail.com</a
          >
        </div>
      </footer>
    </div>
  </template>
</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import randomRef from "@mixins/randomRef";
import "mdb-vue-ui-kit/css/mdb.min.css";
import { formatDateTime } from "/src/utils/utils.js";

export default {
  name: "SpectacleList",

  components: {},

  mixins: [randomRef],

  data() {
    return {
      loading: true,
      spectacles: [],
    };
  },

  spectacleService: null,

  created() {
    this.spectacleService = new SpectacleService();
  },

  mounted() {
    this.spectacleService.comingSpectacles().then((data) => {
      this.spectacles = data;
      this.loading = false;
    });
  },

  methods: {
    isDateAfterToday(date) {
      const today = new Date();
      const spectacleDate = new Date(date);

      return spectacleDate > today;
    },
    formatDateTime,
    goToRegisterPage(specId, dateId) {
      return "/public/spectacle/" + specId + "/register/" + dateId;
      //route.push({name: "spectacleRegister", params: { spectacleId: specId }});
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #f0f1e9;
}

.content-spec-list {
  min-height: 100vh;
  position: relative;
}

.content {
  padding-bottom: 10rem;
}

.custom-button {
  font-size: 12px; /* Ajustez la taille de la police */
  padding: 5px 10px; /* Ajustez les espacements intérieurs du bouton */
}

@media (max-width: 767.98px) {
  /* Styles pour les écrans de taille inférieure à "sm" (767.98px) */
  .custom-button {
    font-size: 12px; /* Réduisez la taille de la police pour les petits écrans */
    padding: 4px 8px; /* Ajustez les espacements intérieurs du bouton pour les petits écrans */
  }
}

/* Style de base pour les boutons */
.btn-floating {
  transition: background-color 0.3s; /* Ajoute une transition en douceur pour le changement de couleur */
  font-size: 16px; /* Ajustez cette valeur en fonction de la taille souhaitée */
  align-items: center;
  justify-content: center;
  height: 100%; /* Ajustez la hauteur pour centrer verticalement */
}

/* Style pour le bouton Facebook lorsqu'il est survolé */
.facebook-button:hover {
  background-color: #1877f2; /* Couleur bleue de Facebook */
  color: white; /* Change la couleur du texte pour s'adapter au fond */
}

/* Style pour le bouton Instagram lorsqu'il est survolé */
.instagram-button:hover {
  background-color: #e1306c; /* Couleur rose d'Instagram */
  color: white; /* Change la couleur du texte pour s'adapter au fond */
}

.pied-de-page {
  width: 100%;
  height: 8rem;
  position: absolute;
  bottom: 0;
}

.image-content {
  img {
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}
</style>
